<template>
  <div class="container">
    <Header />
    <div class="content">
      <div class="banner">
        <img src="../../assets/banner.png" alt="" />
      </div>
      <div class="doubleselectTitle">
        <div class="w" style="display: flex">
          <div class="title">
            <div class="doubleSelectName">
              <!-- {{ doubleSelectInfo.title }} -->
              百日冲刺┃郑州航院2022年家庭经济困难毕业生网络专场双选会
            </div>
            <div class="secondrow">
              <div
                class="doubleSelectstate"
              >
                进行中
              </div>
              <!-- <div class="doubleSelectstatex" v-else>已结束</div> -->
              <div class="each">
                <img src="" alt="" />
                <div>
                  <!-- 举办截止日期：{{ doubleSelectInfo.endTime | timeData }} -->
                  举办截止日期：2022-06-27 16:00
                </div>
              </div>
              <div class="each">
                <img src="" alt="" />
                <div>
                  参加企业：
                  <!-- <span class="number">{{
                    doubleSelectInfo.countOfEnterprise
                  }}</span> -->
                  <span class="number">10</span>
                </div>
              </div>
              <div class="each">
                <img src="" alt="" />
                <div>
                  参与学生：
                  <!-- <span class="number">{{
                    doubleSelectInfo.countOfStudent
                  }}</span> -->
                  <span class="number">1560</span>
                </div>
              </div>
              <div class="each">
                <img src="" alt="" />
                <div>
                  招聘岗位：
                  <!-- <span class="number">{{
                    doubleSelectInfo.countOfPosition!==null?doubleSelectInfo.countOfPosition:'0'
                  }}</span> -->
                  <span class="number">182</span>
                </div>
              </div>
            </div>
          </div>
          <div class="enterassembly">
            <div class="tag" @click="enterhc">进入会场</div>
          </div>
        </div>
      </div>
      <div class="attendSchool w">
        <div
          class="school"
          v-for="item in doubleSelectInfo.jobFairSchoolList"
          :key="item.id"
        >
          <div class="schoolLogo">
            <img :src="item.schoolLogo" alt="" />
          </div>
          <div style="padding-left: 20px">
            <div class="schoolName" :title="item.name">{{ item.name }}</div>
            <div class="schoolEnglishName" :title="item.englishName">{{ item.englishName }}</div>
          </div>
        </div>
      </div>
      <div class="invitationLetter w" style="margin-bottom: 58px">
        <div class="invitationLetterName">
          <img src="" alt="" />
          <div class="title">双选会邀请函</div>
        </div>
        <div class="content" v-html="doubleSelectInfo.blobContent"></div>
      </div>
    </div>
    <Footer />
    <el-dialog
      title="温馨提示"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%"
    >
      <div>
        <div class="flexitem">
          <div><img src="" alt="" /></div>
          <div class="companyTip dashedline">
            企业请通过“我是企业”登录后，通过企业后台报名后进入会场。
          </div>
        </div>
        <div class="flexitem">
          <div><img src="" alt="" /></div>
          <div class="companyTip">
            学生请通过“我是学生”扫码登录后，通过公众号进入会场。
          </div>
        </div>
      </div>
      <div class="sanjiao"></div>
      <div class="sanjiaotwo"></div>
    </el-dialog>
  </div>
</template>
<script>
import { checkTheDetails } from '@/api/self'
// vue文件中引入
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  name: 'Index',
  data () {
    return {
      doubleSelectInfo: {},
      doubleSelectionId: '',
      // 弹框
      dialogVisible: false
    }
  },
  components: {
    Header,
    Footer
  },
  filters: {
    timeData: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.substring(-1, 10)
    }
  },

  methods: {
    // 进入会场
    enterhc () {
      this.dialogVisible = true
    },
    // 双选会详情
    checkTheDetails () {
      checkTheDetails(this.doubleSelectionId)
        .then((res) => {
          if (res.code === 200 || res.code === '200') {
            this.doubleSelectInfo = res.data.records[0]
            console.log(this.doubleSelectInfo)
          }
        })
        .catch(() => {})
    }
  },
  mounted () {
    this.doubleSelectionId = this.$route.query.id
    this.checkTheDetails()
  }
}
</script>
<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}
.w{
  width: 1200px;
  margin: 0 auto;
}
.container {
  min-width: 1180px;
  background: #f8f5f4;
  /deep/.el-dialog{
      position: relative;
      box-shadow: 8px 8px 0px #0D9fFF;
      .flexitem{
        display: flex;
        align-items: center;
      }
      .sanjiao{
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-top: #fff solid 20px;
        border-left: #fff solid 20px;
        position: absolute;
        bottom: -40px;
        left: 60px;
        z-index: 5;
      }
      .sanjiaotwo{
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-top: #0D9fFF solid 20px;
        border-left: #0D9fFF solid 20px;
        position: absolute;
        bottom: -48px;
        left: 68px;
        z-index: 1;
      }
    }
    /deep/.el-dialog__header{
      position: relative;
    }
    /deep/.el-dialog__title{
      display: inline-block;
      line-height: 40px;
      height: 40px;
      width: 110px;
      text-align: center;
      background-color: #0D92FF;
      position: absolute;
      top: -20px;
      left: 20px;
      color: #ffffff;
      box-shadow: 8px 8px 0px #0D9fFF;
    }
    /deep/.el-dialog__body{
      font-size: 16px;
      padding-left: 30px;
      color: #222222;
    }
  .content {
    font-size: 14px;
    line-height: 36px;
    .banner {
      width: 100%;
      height: 450px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .doubleselectTitle {
      width: 100%;
      background: #fff;
      padding: 30px 0;
      .title {
        width: 80%;
        .doubleSelectName {
          font-size: 32px;
          color: #333333;
          margin-bottom: 15px;
        }
        .secondrow {
          display: flex;
          align-items: center;
          .doubleSelectstate {
            width: 64px;
            height: 25px;
            background: #0D92FF;
            border-radius: 2px;
            font-size: 14px;
            color: #ffffff;
            text-align: center;
            line-height: 25px;
          }
          .doubleSelectstatex {
            width: 64px;
            height: 25px;
            background: #999;
            border-radius: 2px;
            font-size: 14px;
            color: #ffffff;
            text-align: center;
            line-height: 25px;
          }
          .each {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 47px;
            margin-left: 10px;
            font-size: 14px;
            color: #666666;
            img {
              width: 16px;
              height: 16px;
              margin-right: 5px;
            }
            .number {
              font-size: 20px;
              color: #0D92FF;
            }
          }
        }
      }
      .enterassembly {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        .tag {
          width: 132px;
          height: 38px;
          cursor: pointer;
          background: #0D92FF;
          border-radius: 4px;
          font-size: 16px;
          text-align: center;
          line-height: 38px;
          color: #ffffff;
        }
      }
    }
    .attendSchool {
      padding: 40px 0 30px;
      .school {
        width: 320px;
        float: left;
        display: flex;
        margin-right: 20px;
        margin-bottom: 20px;
        padding: 20px 30px;
        background: #ffffff;
        border-radius: 4px;
        &:nth-child(3n) {
          margin-right: 0;
        }
        .schoolLogo {
          width: 72px;
          height: 72px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .schoolName {
          font-size: 16px;
          color: #333333;
          width: 230px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .schoolEnglishName {
          font-size: 14px;
          color: #333333;
          width: 230px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &::after {
        content: "";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
      }
    }
    .invitationLetter {
      min-height: 450px;
      background: #fff;

      .invitationLetterName {
        display: flex;
        margin-bottom: 22px;
        padding: 20px;

        img {
          width: 21px;
          height: 21px;
        }
        .title {
          font-size: 16px;
          height: 21px;
          line-height: 21px;
          color: #333333;
          margin-left: 10px;
        }
      }
      .content {
        padding: 20px;
        padding-top: 0;
      }
    }
  }
  /deep/.el-dialog {
    // width: 626px;
    // background: #ffffff;
    // box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.06);

    // /deep/.el-dialog__body {
    //   height: 171px;
    // }
    img {
      padding: 10px;
    }
    .companyTip {
      padding: 10px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #666666;
      margin-left: 9px;
    }
    .dashedline{
      border-bottom: 1px dashed #666666;
    }
    .el-button {
      width: 80px;
      height: 40px;
    }
  }
}
</style>
